import { contractConfigs, tokenConfigs } from './components/contractConfigs.js';
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tokenUtilities.js';
const GDELI = contractConfigs["generic"]["abi"];
const ENERG = contractConfigs["energ"]["abi"];
const MGDELI = contractConfigs["mgdeli"]["abi"];
const GDELI_ADDY = tokenConfigs["gdeli"]["address"]
const ENERG_ADDY = tokenConfigs["energ"]["address"]
const MGDELI_ADDY = tokenConfigs["mgdeli"]["address"]

export class Converter {
	constructor(web3, ref) {
		this.web3 = web3
		this.ref = ref
		this.dec9 = BigNumber.from(10).pow(9)
	}


	cleanInputAmount (amt, approve=false, isRetry=false) {
		console.log("triggered cleaning" + tokenNameAddyMap.get(GDELI_ADDY).name, amt)
		let decNum = fromTokenNameToDecimals(tokenNameAddyMap.get(GDELI_ADDY).name)
		let dec = BigNumber.from(10).pow(decNum)
		let cleanInputAmt = amt.toString();
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		let adjustor = (approve) ? 1 : -1

		let regDot = /[.]/g
		let locationOfDecimal = cleanInputAmt.search(regDot)

		let cleanInput;
		if (locationOfDecimal === -1) {
			cleanInput = cleanInputAmt + "0".repeat(decNum)
		} else {
			// cocksucking decimal time
			let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
			let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
			console.log("integer : " + inputIntegerString)
			console.log("decimal : " + inputDecimalString)
			if (decNum > 0) { // this excludes ZOO from the adjustment
				if (inputDecimalString.length === decNum) {
					// probably hit max button
					cleanInput = inputIntegerString + inputDecimalString
				} else {
					// otherwise see how many decimal places are missing
					let decsMissing = decNum - inputDecimalString.length;

					// and add them
					cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
				}
			} else {

			}
			
		}

		cleanInput = cleanInput.replace(/^0+/,'')
		let lastDigit
		if (isRetry) {
			
			lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
			//console.log(lastDigit)
			cleanInput = cleanInput.substring(0,cleanInput.length - 1)
			//console.log(cleanInput)
			lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
			cleanInput = cleanInput + lastDigit
		}
		if (approve) console.log("approving " + cleanInput)
		if (!approve) console.log("depositing/withdrawing " + cleanInput)
		return cleanInput;
	}

	approveGdeliSpend (spender, callback)  {
		console.log("call to approveGdeliSpend for gDeli burning", (spender) ? " for ENERg" : " for mgDELI")
		console.log(GDELI_ADDY)
		const tract = new this.web3.eth.Contract(GDELI, GDELI_ADDY);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, GDELI_ADDY, true)
		let SPEND = (spender) ? ENERG_ADDY : MGDELI_ADDY
		tract.methods.approve(SPEND, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (spender, callback)  {
		const tract = new this.web3.eth.Contract(GDELI, GDELI_ADDY);
		const SPEND = (spender) ? ENERG_ADDY : MGDELI_ADDY
		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, SPEND)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}


	getBalance (callback) {
		const tract = new this.web3.eth.Contract(GDELI, GDELI_ADDY);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (isEnerg, callback) {
		console.log("triggered deposit ")
		let targetABI = (isEnerg) ? ENERG : MGDELI
		let targetADDY = (isEnerg) ? ENERG_ADDY : MGDELI_ADDY
		const tract = new this.web3.eth.Contract(targetABI, targetADDY)
		let cleanInput = this.cleanInputAmount(this.ref.current.value, GDELI_ADDY)
		if (isEnerg) {
			tract.methods.mintENERg(cleanInput)
				.send({from: window.ethereum.selectedAddress})
					.then(callback)
		} else {
			tract.methods.mintGovernanceToken(cleanInput)
				.send({from: window.ethereum.selectedAddress})
					.then(callback)
		}
	}
	

	triggerApproval(isEnerg, callback) {
		console.log("call to triggerApproval for gDeli burning", (isEnerg) ? " for ENERg" : " for mgDELI")
		this.approveGdeliSpend(
			isEnerg, 
			() => {
				this.checkAllowance(
					isEnerg, 
					callback
				)
				
			}
		)
				
	}

}

