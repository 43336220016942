import { contractConfigs, rewardToken} from './components/contractConfigs.js';
import { scannerBaseURLByChain } from './components/ChainTools.js'
import { DappSection } from './DappSection.js'
const CHEF = contractConfigs["chef"]["address"]

const GdeliLinks = () => (
    <div>
        <a href="https://deliland.io" target="_blank" rel="noreferrer">Deliland</a>
        <a href="https://salt.maxus.blog" target="_blank" rel="noreferrer">Salt</a>
        <a href="https://water.maxus.blog" target="_blank" rel="noreferrer">Water</a>
        <a href="https://acres.maxus.blog" target="_blank" rel="noreferrer">Acres</a>
        <a href="https://yomopu.maxus.blog" target="_blank" rel="noreferrer">Yomopu</a>
        <a href="https://animalfarm.deliland.io" target="_blank" rel="noreferrer">Animal Farm</a>
    </div>
    )

const FarmgodLinks = () => (
    <div>
        <a href="https://app.gitbook.com/@maxusog/s/maxus-multiverse/" target="_blank" rel="noreferrer">Docs</a>
        <a href="https://eyeoftruth.io" target="_blank" rel="noreferrer">Truth</a>
        <a href="https://maxus.blog" target="_blank" rel="noreferrer">Multiverse Wallet</a>
    </div>
)



export const Footer = (props) => (
    <DappSection w={window.innerWidth} h="48" morph={{p:3, newP: "p(3*xMax/4,yMax)"}}>
        <GdeliLinks />
        <FarmgodLinks />
        <p>This app is in like super-duper-early-pre-alpha mode. PUHHLLLEASSEEE do not ape your life savings into this. Use at your own risk, homie. Def not audited.</p>
      </DappSection>
    )