// Author: Ianoda aka Maxus -- maxus.blog
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3'; 
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';
import { Footer } from './footer.js';
import { InputView } from './inputview.js'
import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from './tokenUtilities.js'
// CUSTOM components 
import './App.scss';
import { chainMap, enforceChain } from './components/ChainTools.js';
import { contractConfigs, tokenConfigs } from './components/contractConfigs.js';
import { Converter } from './gdeliConverter.js'
import { PenergConverter } from './penerg.js'
import bg from './images/bg.png'
// MATH STUFF
const dec9 = BigNumber.from(10).pow(9)
const CHEF = contractConfigs["chef"]["address"]
 
function handleChainChange(chainId) {
      window.location.reload();
    }


function App() {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])
  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----



  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [isTargetEnerg, setIsTargetEnerg] = React.useState(true);
  const [isTargetPenerg, setIsTargetPenerg] = React.useState(false);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // this is the web3 instance used throughout the dApp
  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')


//  END SCRIPT

  var converter = new Converter(web3, theInputRef)
  var gdeli = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["gdeli"]["address"])
  var gdeliLP_spiritv1 = new web3.eth.Contract(contractConfigs["spiritv1LP"]["abi"], tokenConfigs["ftmGdeliSpirLP"]["address"])
  var energ = new web3.eth.Contract(contractConfigs["energ"]["abi"], tokenConfigs["energ"]["address"])
  var mgdeli = new web3.eth.Contract(contractConfigs["mgdeli"]["abi"], tokenConfigs["mgdeli"]["address"])
  var penerg = new web3.eth.Contract(contractConfigs["penerg"]["abi"], tokenConfigs["penerg"]["address"])

  var acre = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["acre"]["address"])
  var ugu = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["ugu"]["address"])
  var liggies = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["liggies"]["address"])
  var animal = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["animal"]["address"])

  var wheat = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["wheat"]["address"])
  var cane = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["sugarcane"]["address"])
  var seed = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["gseed"]["address"])

  var water = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["water"]["address"])
  var salt = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["salt"]["address"])
  var yomopu = new web3.eth.Contract(contractConfigs["generic"]["abi"], tokenConfigs["yomopu"]["address"])

  var penergConverter = new PenergConverter(web3, theInputRef)

  const [balanceOfGDeli,setBalanceOfGDeli] = React.useState(0);
  const [balanceOfENERg,setBalanceOfENERg] = React.useState(0);
  const [balanceOfMGDeli,setBalanceOfMGDeli] = React.useState(0);
  const [balanceOfpENERg,setBalanceOfpENERg] = React.useState(0);

  const [balanceOfAcre,setBalanceOfAcre] = React.useState(0);
  const [balanceOfUgu,setBalanceOfUgu] = React.useState(0);
  const [balanceOfLiggies,setBalanceOfLiggies] = React.useState(0);
  const [balanceOfAnimal,setBalanceOfAnimal] = React.useState(0);

  const [balanceOfCane,setBalanceOfCane] = React.useState(0);
  const [balanceOfSeed,setBalanceOfSeed] = React.useState(0);
  const [balanceOfWheat,setBalanceOfWheat] = React.useState(0);
  const [balanceOfWater,setBalanceOfWater] = React.useState(0);

  const [balanceOfSalt,setBalanceOfSalt] = React.useState(0);
  const [balanceOfYomopu,setBalanceOfYomopu] = React.useState(0);

  const [dbalanceOfGDeli,setdBalanceOfGDeli] = React.useState("");
  const [dbalanceOfENERg,setdBalanceOfENERg] = React.useState("");
  const [dbalanceOfMGDeli,setdBalanceOfMGDeli] = React.useState("");
  const [dbalanceOfpENERg,setdBalanceOfpENERg] = React.useState("");

  const [dbalanceOfAcre,setdBalanceOfAcre] = React.useState("");
  const [dbalanceOfUgu,setdBalanceOfUgu] = React.useState("");
  const [dbalanceOfLiggies,setdBalanceOfLiggies] = React.useState("");
  const [dbalanceOfAnimal,setdBalanceOfAnimal] = React.useState("");

  const [dbalanceOfCane,setdBalanceOfCane] = React.useState("");
  const [dbalanceOfSeed,setdBalanceOfSeed] = React.useState("");
  const [dbalanceOfWheat,setdBalanceOfWheat] = React.useState("");
  const [dbalanceOfWater,setdBalanceOfWater] = React.useState("");

  const [dbalanceOfSalt,setdBalanceOfSalt] = React.useState("");
  const [dbalanceOfYomopu,setdBalanceOfYomopu] = React.useState("");

  React.useEffect( () => {
    setdBalanceOfGDeli(formatTokenBalance(9,balanceOfGDeli))
  }, [balanceOfGDeli])
  React.useEffect( () => {
    setdBalanceOfENERg(formatTokenBalance(9,balanceOfENERg))
  }, [balanceOfENERg])
  React.useEffect( () => {
    setdBalanceOfMGDeli(formatTokenBalance(9,balanceOfMGDeli))
  }, [balanceOfMGDeli])
  React.useEffect( () => {
    setdBalanceOfpENERg(formatTokenBalance(9,balanceOfpENERg))
  }, [balanceOfpENERg])
  React.useEffect( () => {
    setdBalanceOfAcre(formatTokenBalance(18,balanceOfAcre))
  }, [balanceOfAcre])
  React.useEffect( () => {
    setdBalanceOfUgu(formatTokenBalance(18,balanceOfUgu))
  }, [balanceOfUgu])
  React.useEffect( () => {
    setdBalanceOfLiggies(formatTokenBalance(18,balanceOfLiggies))
  }, [balanceOfLiggies])
  React.useEffect( () => {
    setdBalanceOfAnimal(formatTokenBalance(18,balanceOfAnimal))
  }, [balanceOfAnimal])
  React.useEffect( () => {
    setdBalanceOfCane(formatTokenBalance(18,balanceOfCane))
  }, [balanceOfCane])
  React.useEffect( () => {
    setdBalanceOfWheat(formatTokenBalance(18,balanceOfWheat))
  }, [balanceOfWheat])
  React.useEffect( () => {
    setdBalanceOfSeed(formatTokenBalance(18,balanceOfSeed))
  }, [balanceOfSeed])
  React.useEffect( () => {
    setdBalanceOfWater(formatTokenBalance(18,balanceOfWater))
  }, [balanceOfWater])
  React.useEffect( () => {
    setdBalanceOfYomopu(formatTokenBalance(18,balanceOfYomopu))
  }, [balanceOfYomopu])
  React.useEffect( () => {
    setdBalanceOfSalt(formatTokenBalance(18,balanceOfSalt))
  }, [balanceOfSalt])


  const getBalance = (token) => {
    if (token == 0) {
      gdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfGDeli(res))
    } else if (token == 1) {
      energ.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfENERg(res))
    } else if (token == 2) {
      mgdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfMGDeli(res))
    } else if (token == 3) {
      penerg.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfpENERg(res))
    } else if (token == 4) {
      acre.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAcre(res))
    } else if (token == 5) {
      ugu.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfUgu(res))
    } else if (token == 6) {
      liggies.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfLiggies(res))
    } else if (token == 7) {
      animal.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAnimal(res))
    } else if (token == 8) {
      wheat.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfWheat(res))
    } else if (token == 9) {
      cane.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfCane(res))
    } else if (token == 10) {
      seed.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfSeed(res))
    } else if (token == 11) {
      salt.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfSalt(res))
    } else if (token == 12) {
      water.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfWater(res))
    } else if (token == 13) {
      yomopu.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfYomopu(res))
    }
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance)
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    if (!isTargetPenerg) {
      converter.triggerApproval(
      isTargetEnerg, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
    } else {
      let convertingENERgTopENERg = (theInputINTENT == "add") ? true : false;
      penergConverter.triggerApproval(
      convertingENERgTopENERg, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
    }
    
  }

  // -- end of Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // onClick function factory for building appropriate input overlay
  const openInputViewAndDoStuff = (isEnerg, intent = "add", isPenergConversion = false) => {
    if (!isPenergConversion) {
      return () => {

        toggleInput()
        setIsTargetEnerg(isEnerg)
        setIsTargetPenerg(false)
        setTheInputINTENT(intent)
        smartSetBalanceOfTarget(balanceOfGDeli)
        converter.checkAllowance(isEnerg, (allowance) => smartSetAllowanceOfTarget(allowance))

      }
    } else {
        return () => {

          toggleInput()
          setIsTargetEnerg(false)
          setIsTargetPenerg(true)
          setTheInputINTENT(intent)
          smartSetBalanceOfTarget((intent == "add") ? balanceOfENERg : balanceOfpENERg)
          penergConverter.checkAllowance((intent == "add") ? true : false, (allowance) => smartSetAllowanceOfTarget(allowance))
        }
    }
    

  }

   const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(9, balanceOfpENERg, false)
    } else {
      theInputRef.current.value = formatTokenBalance(9, balanceOfTarget, false)
    }
    
  }

  // onClick function for depositing
  const triggerDeposit = () => {
    setIsPending(true)
    if (!isTargetPenerg) {
      converter.depositAmount(
      isTargetEnerg,
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(0)
        getBalance((isTargetEnerg) ? 1 : 2)
        }    
      )
    } else {
      penergConverter.depositAmount(
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(1)
        getBalance(3)
        }    
      )
    }
    
  }


  const [lpReserve0, setLpReserve0] = React.useState(0);
  const [lpReserve1, setLpReserve1] = React.useState(0);
  const [dlpReserve0, setdLpReserve0] = React.useState("");
  const [dlpReserve1, setdLpReserve1] = React.useState("");

  const [gDeliImpliedPrice, setGDeliImpliedPrice] = React.useState()

  React.useEffect( () => {
    setdLpReserve0(formatTokenBalance(18,lpReserve0))
  }, [lpReserve0])
  React.useEffect( () => {
    setdLpReserve1(formatTokenBalance(9,lpReserve1))
  }, [lpReserve1])

  const getReserves = () => {
    gdeliLP_spiritv1.methods.getReserves().call({from: window.ethereum.selectedAddress})
      .then((reserves) => {
        setLpReserve0(reserves[0])
        setLpReserve1(reserves[1])

      })
  }
  

  const triggerWithdraw = () => {
    setIsPending(true)
    penergConverter.withdrawAmount(
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(1)
        getBalance(3)
        }    
      )
  }


  const letItRip = () => {
    for (var i =0; i < 14; i++) {
      getBalance(i)
    }
  }


  return (
    <div className={"App"}>
    <div className="bg"><img src={bg} /></div>
    <header className="App-header">
      <img src={tokenConfigs["gdeli"]["icon"]["default"]} className="App-logo" alt="logo" /> 
      <div className="App-name">Deliland National Bank</div> 
    </header>
      <button className="metamask-btn" onClick={connectMM}>{mmBtnText}</button>
      <div className="App-core">
        <div className="App-userBalances">
          <div>
            <img src={tokenConfigs["gdeli"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
            <span>{dbalanceOfGDeli}</span>
          </div>
          <div>
            <img src={tokenConfigs["energ"]["icon"]["default"]} className="token-icon" alt="energ" /> 
            <span>{dbalanceOfENERg}</span>
          </div>
          <div>
            <img src={tokenConfigs["penerg"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
            <span>{dbalanceOfpENERg}</span>
          </div>
          <div>
            <img src={tokenConfigs["mgdeli"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
            <span>{dbalanceOfMGDeli}</span>
          </div>
        </div>

        <div className="App-converter">
          <div className="App-converter__title">
            Mint ENERg by Burning gDELI
          </div>
          <div className="App-converter__info">
            <p>ENERg can be provided to the Deliland Energy Grid.</p>
            <p>1 gDELI is burned to create 1 ENERg. Because of the transaction tax on gDELI, burning 1 gDELI only burns 0.98 gDELI and mints 1 ENERg.</p>
            <p>This process is irreversible</p>
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(true,"add")}>Mint</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Mint mgDELI by Burning gDELI
          </div>
          <div className="App-converter__info">
            <p>mgDELI is used to govern Deliland.</p>
            <p>1 gDELI is burned to create 1000 mgDELI. Because of the transaction tax on gDELI, burning 1 gDELI only burns 0.98 gDELI and mints 1000 mgDELI.</p>
            <p>This process is irreversible</p>
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"add")}>Mint</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Manage your ENERg provided to the Deliland Power Grid
          </div>
          <div className="App-converter__info">
            <p>When you provide ENERg to the power grid, you recieve pENERg. 1 ENERg = 1 pENERg</p>
            <p>pENERg can be staked for rewards, held for refinery tax distributions, and withdrawn back to ENERg at any time.</p>
            
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"add",true)}>Provide</button>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"remove",true)}>Remove</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Your Deliland Assets
          </div>
          <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Core</div>
            <div>
              <img src={tokenConfigs["gdeli"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfGDeli}</span>
            </div>
            <div>
              <img src={tokenConfigs["energ"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfENERg}</span>
            </div>
            <div>
              <img src={tokenConfigs["penerg"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfpENERg}</span>
            </div>
            <div>
              <img src={tokenConfigs["mgdeli"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
              <span>{dbalanceOfMGDeli}</span>
            </div>
        </div>
        <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Extended Core</div>
            <div>
              <img src={tokenConfigs["acre"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfAcre}</span>
            </div>
            <div>
              <img src={tokenConfigs["ugu"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfUgu}</span>
            </div>
            <div>
              <img src={tokenConfigs["liggies"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfLiggies}</span>
            </div>
            <div>
              <img src={tokenConfigs["animal"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
              <span>{dbalanceOfAnimal}</span>
            </div>

        </div>
        <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Raw Materials</div>
            <div>
              <img src={tokenConfigs["gseed"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfSeed}</span>
            </div>
            <div>
              <img src={tokenConfigs["wheat"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfWheat}</span>
            </div>
            <div>
              <img src={tokenConfigs["sugarcane"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfCane}</span>
            </div>
           

        </div>
        <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Ocean</div>
            <div>
              <img src={tokenConfigs["water"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfWater}</span>
            </div>
            <div>
              <img src={tokenConfigs["salt"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfSalt}</span>
            </div>
          
           

        </div>

        <div className="App-Folio">
            <div className="Portfolio-Title">Yomopu Fields</div>
         
            <div>
              <img src={tokenConfigs["yomopu"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfYomopu}</span>
            </div>
           

        </div>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Market Making Tools
          </div>
          <p>Full kit of market making tools coming soon!</p>
          <p>Spirit V1 gDELI-FTM LP</p>
          <p>gDELI Reserves: {dlpReserve1}</p>
          <p>FTM Reserves: {dlpReserve0}</p>
          <button className="btn" onClick={getReserves}>Check</button>
        </div>
      </div>

      <InputView 
        title={(isTargetEnerg) ? "Minting ENERg" : "Minting mgDELI"}
        isEnerg={isTargetEnerg}
        isPenerg={isTargetPenerg}
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={(isTargetPenerg) ? formatTokenBalance(9,balanceOfpENERg) : formatTokenBalance(9, (isTargetEnerg) ? balanceOfENERg : balanceOfMGDeli)}
        balance={(isTargetPenerg) ? formatTokenBalance(9,balanceOfENERg) : formatTokenBalance(9,balanceOfGDeli)}
        allowance={formatTokenBalance(9, allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />

      <div className={"pending pending--" + isPending}>Pending Transaction</div>

    <Footer />
   </div>
  );
}

export default App;
