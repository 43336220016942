

export const contractConfigs = {
	"generic": {
		"abi": require("../abis/genABI-dai.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("../abis/chef.json"),
		"address": "0x3c559c90fD1c7a73D6EaC8510B8Fb16Ca333226F"
	},
	"energ": {
		"abi": require("../abis/energ.json"),
		"address": "0x126eE076DA8cc4ffCA5cd89BB4a0EbfE6Fa09189"
	},
	"penerg": {
		"abi": require("../abis/penerg.json"),
		"address": "0x5740bc97388e468829e5d43bcf3e56dac65fdc1a"
	},
	"providers": {
		"abi": require("../abis/energproviderrewards.json"),
		"address": "0xC508098a50CFAe6f424F48D63F07a195F76A6345"
	},
	"mgdeli": {
		"abi": require("../abis/mgdeli.json"),
		"address": "0x16DCdC7BB3235a60243b34875c9D86a4980Da26d"
	},
	"spiritv1LP": {
		"abi": require("../abis/spiritv1LP.json")
	}
}

export const poolConfigs = {
	"0": {
		"stake-name": "penerg",
		"name": "pENERg",
		"reward": "open"
		}
}

export const tokenConfigs = {
	"acre": {
		"name": "ACRE",
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D",
		"icon": require("../images/token-icons/acre128.png"),
		"decimals": 18
	},
	"gdeli": {
		"name": "gDELI",
		"address": "0xfa1807bd24a438b389ca33072375dfbba74dfaab",
		"icon": require("../images/token-icons/gdeli.png"),
		"decimals": 9
	},
	"echo": {
		"name": "ECHO",
		"address": "0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd",
		"icon": require("../images/token-icons/echo128.png"),
		"decimals": 9
	},
	"ftmGdefHyperLP": {
		"name": "FTM-GIGADEFLATON HLP",
		"address": "0xa8f8ce6959d70dc699d6d603319353547daa3eae",
		"icon": require("../images/token-icons/gigadeflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmDefHyperLP": {
		"name": "FTM-DEFLATON HLP",
		"address": "0x925216d9d3277eb05b009e852be6e37d253d83c6",
		"icon": require("../images/token-icons/deflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmGdefSushiLP": {
		"name": "FTM-GIGADEFLATON SushiLP",
		"address": "0x24623f2a4ec70e81862469fbab10900b51389df1",
		"icon": require("../images/token-icons/gigadeflatonftmSLP.png"),
		"decimals": 18
	},
	"ftmDefSushiLP": {
		"name": "FTM-DEFLATON SushiLP",
		"address": "0x0281596a54d2876e827dc2eeb2102210f204decd",
		"icon": require("../images/token-icons/deflatonftmSLP.png"),
		"decimals": 18
	},
	"catnip": {
		"name": "CATNIP",
		"address": "0xBbd946041824416A4e2EB37d5880718B3812c9Cb",
		"icon": require("../images/token-icons/catnip.png"),
		"decimals": 18
	},
	"ftmHodlZooLP": {
		"name": "FTM-HODL ZooLP",
		"address": "0xdd9ef5358e54faef385fcf27be2ef4b2de469ac4",
		"icon": require("../images/token-icons/hodlftmZLP.png"),
		"decimals": 18
	},
	"ftmGdeliZooLP": {
		"name": "FTM-GDELI ZooLP",
		"address": "0xc2a826d7732bbb5c33768a233de05e78ae274d00",
		"icon": require("../images/token-icons/ftmgdeliZLP.png"),
		"decimals": 18
	},
	"zoo": {
		"name": "Zoo",
		"address": "0x09e145a1d53c0045f41aeef25d8ff982ae74dd56",
		"icon": require("../images/token-icons/ZOO.png"), //./images/token-icons/matic.png"
		"decimals": 0
	},
	"ftmEchoZooLP": {
		"name": "FTM-ECHO ZooLP",
		"address": "0x79d25d00C159BAf1bdE8831B00e2f7370Bf5Fe46",
		"icon": require("../images/token-icons/ftmechoZLP.png"),
		"decimals": 18
	},
	"foo": {
		"name": "FOO",
		"address": "0xfbc3c04845162f067a0b6f8934383e63899c3524",
		"icon": require("../images/token-icons/foo.png"),
		"decimals": 18
	},
	"ftmAnimalZooLP": {
		"name": "FTM-ANIMAL ZLP",
		"address": "0x9d4285681eeba3d83dbbca2dde19cf592a1bc02e",
		"icon": require("../images/token-icons/ftmanimalzlp.png"),
		"decimals": 18
	},
	"ftmAcresZooLP": {
		"name": "FTM-ACRE ZLP",
		"address": "0xe8dfe2191bb1fe797dfdc747223c440a19bfdac4",
		"icon": require("../images/token-icons/ftmacreszlp.png"),
		"decimals": 18
	},
	"bomb": {
		"name": "BOMB",
		"address": "0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378",
		"icon": require("../images/token-icons/bomb.png"),
		"decimals": 0
	},
	"ftmEchoSpirLP": {
		"name": "FTM-ECHO Spirit LP",
		"address": "0x26c2be4dff687504faf0f45b35149ed1288b40a4",
		"icon": require("../images/token-icons/ftmechospiritlp.png"),
		"decimals": 18
	},
	"ftmGdeliSpirLP": {
		"name": "FTM-gDELI Spirit LP",
		"address": "0x2cbbfde25dedbba0bd2d07206dcdd4194fdbcc90",
		"icon": require("../images/token-icons/ftmgdelispiritlp.png"),
		"decimals": 18
	},
	"ftmAcresSpirLP": {
		"name": "FTM-ACRE Spir LP",
		"address": "0x63594b5011d9d7e2ed54f35f3db6ce797688ed1d",
		"icon": require("../images/token-icons/ftmacreszlp.png"),
		"decimals": 18
	},
	"energ": {
		"name": "ENERg",
		"address": "0x126eE076DA8cc4ffCA5cd89BB4a0EbfE6Fa09189",
		"icon": require("../images/token-icons/EnerG-icon.png"),
		"decimals": 9
	},
	"penerg": {
		"name": "ENERg",
		"address": "0x5740bc97388e468829e5d43bcf3e56dac65fdc1a",
		"icon": require("../images/token-icons/pEnerG-icon.png"),
		"decimals": 9
	},
	"mgdeli": {
		"name": "ENERg",
		"address": "0x16DCdC7BB3235a60243b34875c9D86a4980Da26d",
		"icon": require("../images/token-icons/mgDELI-icon.png"),
		"decimals": 9
	},
	"ugu": {
		"name": "UGU",
		"address": "0x3f9C449FEBb5e8cC452637ACe9287dD806F49B86",
		"icon": require("../images/token-icons/deliland-ugu-icon.png"),
		"decimals": 18
	},
	"liggies": {
		"name": "LIGGIES",
		"address": "0x6e9c4Fe1D8509ED2d4219C21FbaB232A81b8d4b6",
		"icon": require("../images/token-icons/deliland-liggies-icon.png"),
		"decimals": 18
	},
	"sugarcane": {
		"name": "CANE",
		"address": "0x54546a860416483994E7E83803A72d59D15fBAA3",
		"icon": require("../images/token-icons/deliland-cane-icon.png"),
		"decimals": 18
	},
	"wheat": {
		"name": "BUSHEL",
		"address": "0xB6F07FcD05E90D46b411f4F4F1c0665227bCdd35",
		"icon": require("../images/token-icons/deliland-wheat-icon.png"),
		"decimals": 18
	},
	"gseed": {
		"name": "gSEED",
		"address": "0xDbE808E1968B0C8978Ad7d13255CBA8aC659Ea0a",
		"icon": require("../images/token-icons/deliland-gseed-icon.png"),
		"decimals": 18
	},
	"raw3pool": {
		"name": "BPT-gRAW",
		"address": "0x6dC1b4eC32da1F77d3e1e6Fb0b4ac01f8179821b",
		"icon": require("../images/token-icons/deliland-gseed-icon.png"),
		"decimals": 18
	},
	"animal": {
		"name": "ANIMAL",
		"address": "0x1f365ac62aabf64ef52a2c2fd86df9dd9e03665f",
		"icon": require("../images/token-icons/animal.png"),
		"decimals": 18
	},
	"yomopu": {
		"name": "YOMOPU",
		"address": "0x6FA9979E4403C0048eb4cf830c5B088459e20939",
		"icon": require("../images/token-icons/YOMO256.png"),
		"decimals": 18,
		"type": "ingredient",
		"max": "∞"
	},
	"salt": {
		"name": "SALT",
		"address": "0x681a02302213c09171ddd45232cc5652e95ccc83",
		"icon": require("../images/token-icons/salt.png"),
		"decimals": 18,
		"type": "ingredient",
		"max": "∞",
	},
	"water": {
		"name": "gH20",
		"address": "0xD802dF71F737F9C687c5d389FD6AC421d0666f10",
		"icon": require("../images/token-icons/watertoken192.png"),
		"decimals": 18,
		"type": "ingredient",
		"max": "∞"
	},
	"wftm": {
		"name": "WFTM",
		"address": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
		"icon": require("../images/token-icons/FTM.png"),
		"decimals": 18
	}

}